.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

button.btn-outline-danger {
    color: #C45911;
    border-color: #C45911;
}

button.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.5);
}

button.btn-outline-danger:hover,
button.btn-outline-danger:focus,
button.btn-outline-danger:active {
    color: white; /* Change text color on hover */
    background-color: #C45911; /* Change background color on hover */
    border-color: #C45911; /* Change border color on hover */
}

.container {
    display: flex;
}

.left, .right {
    flex: 1;
    padding: 10px 5px;
    box-sizing: border-box;
}

@media (max-width: 600px) {
    .left, .right {
        flex: 100%;
    }
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.2s;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
}
